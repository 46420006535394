import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { getEnvironmentConfig } from '@sulser-print/tokens/environment.token';

@Injectable({
	providedIn: 'any',
})
export class RequestService {
	environment = getEnvironmentConfig();

	protected readonly httpClient = inject(HttpClient);
}
