import { InjectionToken, inject } from '@angular/core';

const ENVIRONMENT_TOKEN = new InjectionToken('ENVIRONMENT_TOKEN');

export interface SharedEnvironmentConfig {
	api: string;
	apiVersion: string;
	currentEnvironment: string;
	publicApiVersion: string;
}

export function provideEnvironment<T extends SharedEnvironmentConfig>(environmentConfiguration: T) {
	return {
		provide: ENVIRONMENT_TOKEN,
		useValue: environmentConfiguration,
	};
}

export function getEnvironmentConfig<T extends SharedEnvironmentConfig>() {
	return inject(ENVIRONMENT_TOKEN) as T;
}
